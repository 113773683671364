<template>
  <div class="calendar">
    <h2>Ufficio {{ office_id + 1 }}</h2>
    <ApolloQuery :query="require('../graphql/Meetings.gql')" :variables="{ office_id, confirmed: true }">
      <template v-slot="{ result: { error, data }, isLoading }">
        <div v-if="isLoading">
          <h2>Loading...</h2>
        </div>
        <div v-else-if="error">
          <h2>Errore nell'acquisizione dei dati</h2>
        </div>
        <div v-else-if="data">
          <!-- <div v-for="meeting in data.meetings" v-bind:key="meeting.id">{{meeting.id}}</div> -->
          <full-calendar
            :options="{
              ...calendarOptions,
              events: formatEvents(data.meetings),
              eventColor: colors[office_id],
            }"></full-calendar>
        </div>
      </template>
    </ApolloQuery>
    <b-modal title="Prenota appuntamento" hide-footer v-model="createModal">
      <new-reservation-form :reservationInfo="reservationInfo" v-if="firstStep" @created="created" />
    </b-modal>
    <b-modal v-if="currentEvent" title="Informazioni prenotazione" hide-footer v-model="summaryModal">
      <reservation-summary @deleted="deleted" :event_id="parseInt(currentEvent.id)" />
    </b-modal>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import itLocale from "@fullcalendar/core/locales/it";
import moment from "moment";
import ReservationSummary from "./ReservationSummary.vue";
import NewReservationForm from "./NewReservationForm.vue";

export default {
  name: "MeetingCalendar",

  props: {
    office_id: { type: Number, required: true },
  },

  components: {
    FullCalendar,
    ReservationSummary,
    NewReservationForm,
  },

  data() {
    return {
      colors: ["#0074D9", "#3D9970", "#DD4136"],

      createModal: false,
      summaryModal: false,
      start: "2024-09-17",

      error: "",

      currentCalendar: null,
      currentMeeting: null,
      currentEvent: null,

      meeting_start: "",
      meeting_end: "",

      firstStep: true,
    };
  },

  computed: {
    reservationInfo() {
      return {
        office_id: this.office_id,
        meeting_start: this.meeting_start,
        meeting_end: this.meeting_end,
      };
    },

    calendarOptions() {
      return {
        plugins: [timeGridPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        locale: itLocale,
        allDaySlot: false,
        slotDuration: "00:15:00",
        slotMinTime: "17:30:00",
        slotMaxTime: "18:30:00",
        slotEventOverlap: false,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        selectable: true,
        validRange: this.validRange,
        editable: false,
        height: "600px",
        displayEventTime: false,
        expandRows: true,
        nowIndicator: true,
        firstDay: 2,
        slotLabelInterval: "00:15",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },
        hiddenDays: [0, 1, 3, 4, 5, 6],
        selectAllow: ({ start, end }) => (end.getTime() - start.getTime()) / 60000 <= 15,
      };
    },

    validRange() {
      return {
        start: moment().isAfter(moment(this.start)) ? moment().format("YYYY-MM-DD") : this.start,
        // start: "2022-10-10",
        // end: "2022-10-17",
      };
    },
  },

  methods: {
    handleDateSelect(selectInfo) {
      this.meeting_start = moment(selectInfo.startStr).format("YYYY-MM-DD HH:mm:ss");
      this.meeting_end = moment(selectInfo.endStr).format("YYYY-MM-DD HH:mm:ss");
      this.createModal = true;
      this.currentCalendar = selectInfo.view.calendar;
    },

    handleEventClick(clickInfo) {
      this.currentEvent = clickInfo.event;
      this.summaryModal = true;
    },

    created(newReservation) {
      this.createModal = false;
      this.$bvToast.toast(`Prenotazione: ${moment(newReservation.meeting_start).format("LLL")}`, {
        title: `Prenotazione effettuata!`,
        variant: "success",
        solid: true,
      });
    },

    deleted(reservation) {
      this.summaryModal = false;
      this.$bvToast.toast(`Prenotazione eliminata: ${reservation.name}`, {
        title: `Prenotazione eliminata`,
        variant: "warning",
        solid: true,
      });
    },

    handleEvents(events) {
      this.currentEvents = events;
    },

    formatEvents(meetings) {
      let formattedMeetings = meetings.map((meeting) => ({
        id: meeting.id,
        title: meeting.name,
        start: meeting.meeting_start,
        end: meeting.meeting_end,
      }));

      return formattedMeetings;
    },
  },
};
</script>

<style lang="scss">
.fc-event-title {
  height: 100%;
  text-align: center;
}
</style>
